import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://app.ebisusbay.com/dex/swap',
      },
      {
        label: 'Add liquidity',
        href: 'https://app.ebisusbay.com/dex/liquidity',
      }
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  }, 
  {
     label: 'Pools',
     icon: 'PoolIcon',
     href: '/pools',
   }, 
     {
    label: 'IFO',
    icon: 'IfoIcon',
    href: '/ifo',
  }, 
 /* {
    label: 'IFO',
    icon: 'IfoIcon',
    href: '/ifo',
  }, */
  /*  {
      label: 'Lottery',
      icon: 'TicketIcon',
      href: '/lottery',
    }, */
  /* {
     label: 'NFT',
     icon: 'NftIcon',
     href: '/nft',
   }, */
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },

 /* {
    label: 'Listing',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Debank',
        href: 'https://debank.com/protocols/ETH_KiwiSwap',
      },
      {
        label: 'Coingecko',
        href: 'https://www.coingecko.com/en/coins/KiwiSwap-2',
      },
      {
        label: 'Dexscreener',
        href: 'https://dexscreener.com/ETHchain/0x5f90b42568a08ecfa3c5fe8cd053719863f59a22',
      },
      {
        label: 'Defillama',
        href: 'https://defillama.com/protocol/KiwiSwap#information',
      }
    ],
  }, */
  {
    label: 'More',
    icon: 'ToolsIcon',
    items: [
      {
        label: 'Docs',
        href: 'https://kiwiswap.gitbook.io/kiwiswap',
      },
    ],
  }
]

export default config
