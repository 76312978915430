import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  /* {
    pid: 0,
    risk: 5,
    lpSymbol: 'CAKE-USDC LP',
    lpAddresses: {
      388: '',
      1: '0x4200000000000000000000000000000000000006', // TODO
    },
    tokenSymbol: 'Kiwi',
    tokenAddresses: {
      388: '',
      1: '0x4200000000000000000000000000000000000006', // TODO
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.USDC,
  }, */
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'KIWI-CRO LP',
    lpAddresses: {
      388: '0xd72D8aBf30765d815Fde32d6A609FA2F2476D24F',
      1: '0xd72D8aBf30765d815Fde32d6A609FA2F2476D24F', // TODO
    },
    tokenSymbol: 'KIWI',
    tokenAddresses: {
      388: '0x492AdEF6630991845df5d684E31c648cA70Da9F3',
      1: '0x492AdEF6630991845df5d684E31c648cA70Da9F3', // TODO
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

/*  {
    pid: 2,
    risk: 5,
    lpSymbol: 'ETH-USDC LP',
    lpAddresses: {
      388: '0x12D5352A4819993e8748dF8B7759610c250cea1f',
      1: '0x12D5352A4819993e8748dF8B7759610c250cea1f', // TODO
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      388: '0x30005Fd5eE0480D0ccFdDe168BBe4527Ffb27062',
      1: '0x30005Fd5eE0480D0ccFdDe168BBe4527Ffb27062', // TODO
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  }, */
  {
    pid: 1,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'KIWI',
    lpAddresses: {
      388: '0xd72D8aBf30765d815Fde32d6A609FA2F2476D24F',
      1: '0xd72D8aBf30765d815Fde32d6A609FA2F2476D24F',
    },
    tokenSymbol: 'Kiwi',
    tokenAddresses: {
      388: '0x492AdEF6630991845df5d684E31c648cA70Da9F3',
      1: '0x492AdEF6630991845df5d684E31c648cA70Da9F3', // TODO
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  }, 
  {
    pid: 2,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'WCRO',
    lpAddresses: {
      388: '0xd72D8aBf30765d815Fde32d6A609FA2F2476D24F',
      1: '0xd72D8aBf30765d815Fde32d6A609FA2F2476D24F',
    },
    tokenSymbol: 'WCRO',
    tokenAddresses: {
      388: '0x492AdEF6630991845df5d684E31c648cA70Da9F3',
      1: '0x492AdEF6630991845df5d684E31c648cA70Da9F3', // TODO
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  }, 
]

export default farms
