export default {
  cake: {
    1: '0x492AdEF6630991845df5d684E31c648cA70Da9F3',
    388: '0x492AdEF6630991845df5d684E31c648cA70Da9F3',
  },
  masterChef: {
    1: '0x72ECb103A8d57c9121a74E707e15D1D71F450514',
    388: '0x72ECb103A8d57c9121a74E707e15D1D71F450514',
  },
  wbnb: {
    1: '0x4200000000000000000000000000000000000006',
    388: '0x4200000000000000000000000000000000000006',
  },
  multi: {
    1: '0x06f4487d7c4a5983d2660db965cc6d2565e4cfaa',
    388: '0x06f4487d7c4a5983d2660db965cc6d2565e4cfaa',
  },
  lottery: {
    1: '',
    388: '',
  },
  lotteryNFT: {
    1: '',
    388: '',
  },
  mulltiCall: {
    1: '0xB7c3Ea932649fBD594C881aDCb3F19415e2EA2d5', // TODO
    388: '0xB7c3Ea932649fBD594C881aDCb3F19415e2EA2d5',
  },
  USDC: {
    1: '0x30005Fd5eE0480D0ccFdDe168BBe4527Ffb27062',
    388: '0x30005Fd5eE0480D0ccFdDe168BBe4527Ffb27062',
  },
  TN100X: {
    1: '0xE8DD44d0791B73afe9066C3A77721f42d0844bEB',
    388: '0xE8DD44d0791B73afe9066C3A77721f42d0844bEB',
  },
  Kiwi: {
    1: '0x492AdEF6630991845df5d684E31c648cA70Da9F3',
    388: '0x492AdEF6630991845df5d684E31c648cA70Da9F3',
  },
 /* Kiwi: {
    1: '0x492AdEF6630991845df5d684E31c648cA70Da9F3',
    388: '',
  },
  JAAD: {
    1: '0x373e256C754Bf82f09071141b92bEafb83c38c68',
    388: '',
  },
  GOAT: {
    1: '0xca319f4A4f28EB59AcE61E2D2d64ee2A7eaF65B3',
    388: '',
  },
  HELMET: {
    1: '0xfC6601a0Fcaf2252332eE2b0Ea213bbB5F977117',
    388: '',
  } */
}
