import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
/*  {
    sousId: 1,
    tokenName: 'WETH',
    stakingTokenName: QuoteToken.Kiwi,
    stakingTokenAddress: '0x492AdEF6630991845df5d684E31c648cA70Da9F3',
    contractAddress: {
      388: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
      1: '0x7aF96441Fe5CD77eCDBDf31A79Adbe7502753dE1',
    },
    poolCategory: PoolCategory.COMMUNITY,
    projectLink: 'https://Kiwiswap.xyz',
    harvest: true,
    tokenPerBlock: '0.008267195767',
    sortOrder: 1,
    isFinished: true,
    tokenDecimals: 18,
  }, */
 /* {
    sousId: 1,
    tokenName: 'WETH',
    stakingTokenName: QuoteToken.Kiwi,
    stakingTokenAddress: '0x492AdEF6630991845df5d684E31c648cA70Da9F3',
    contractAddress: {
      388: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
      1: '0xc4149B9D249032faE4D71EFdaE702550e00E8199',
    },
    poolCategory: PoolCategory.COMMUNITY,
    projectLink: 'https://Kiwiswap.xyz',
    harvest: true,
    tokenPerBlock: '0.008267195767',
    sortOrder: 3,
    isFinished: false,
    tokenDecimals: 18,
  }, */



  /* {
    sousId: 1,
    tokenName: 'TWT',
    stakingTokenName: QuoteToken.SYRUP,
    stakingTokenAddress: '0x492AdEF6630991845df5d684E31c648cA70Da9F3',
    contractAddress: {
      388: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
      1: '0xaB42EE05ceb48AC8f4d5782E4512D987694802b9',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://trustwallet.com/',
    harvest: true,
    tokenPerBlock: '20',
    sortOrder: 999,
    isFinished: true,
    tokenDecimals: 18,
  }, */
  // {
  //   sousId: 0,
  //   tokenName: 'Kiwi',
  //   stakingTokenName: QuoteToken.Kiwi,
  //   stakingTokenAddress: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  //   contractAddress: {
  //     388: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
  //     1: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://pancakeswap.finance/',
  //   harvest: true,
  //   tokenPerBlock: '10',
  //   sortOrder: 1,
  //   isFinished: false,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 1,
  //   tokenName: 'TWT',
  //   stakingTokenName: QuoteToken.SYRUP,
  //   stakingTokenAddress: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
  //   contractAddress: {
  //     388: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
  //     1: '0xAfd61Dc94f11A70Ae110dC0E0F2061Af5633061A',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://trustwallet.com/',
  //   harvest: true,
  //   tokenPerBlock: '20',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },

]

export default pools
